import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AddressForm.css';  // Import the custom CSS file
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddressForm() {
    const [formData, setFormData] = useState({
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const apiKey = urlParams.get('apikey');

            if (!apiKey) {
                toast.error('Missing key');
                return;
            }

            try {
                const ipResponse = await axios.get('https://ipinfo.io/ip');
                const ipAddress = ipResponse.data.trim();
                const response = await axios.get('https://apis.themindson.com/info-service/get-address', {
                    headers: {
                        'apikey': apiKey
                    },
                    params: {
                        'ip': ipAddress
                    },
                    timeout: 60000
                });
                if (response.status === 200) {
                    setFormData(response.data);
                } else {
                    setFormData({
                        address: 'NULL',
                        city: 'NULL',
                        state: 'NULL',
                        zipcode: 'NULL',
                        country: 'NULL'
                    });
                    toast.error('Error fetching data');
                }
            } catch (error) {
                setFormData({
                    address: 'NULL',
                    city: 'NULL',
                    state: 'NULL',
                    zipcode: 'NULL',
                    country: 'NULL'
                });
                toast.error('Error fetching data');
            }
        };

        fetchData();
    }, []);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleCopy = (field) => {
        navigator.clipboard.writeText(formData[field])
            .then(() => {
                toast.success(`${field.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())} copied to clipboard!`);
            })
            .catch(() => {
                toast.error('Failed to copy');
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission logic here
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <fieldset>
                    {Object.keys(formData).map((key) => (
                        <div key={key} className="form-field">
                            <label htmlFor={key} style={{textTransform: 'capitalize'}}>
                                {key.replace('_', ' ')}:
                            </label>
                            <input
                                id={key}
                                type="text"
                                value={formData[key]}
                                onChange={handleChange}
                                name={key}
                                autoComplete={getAutoCompleteValue(key)}
                            />
                            <button type="button" onClick={() => handleCopy(key)}>Copy</button>
                        </div>
                    ))}
                    <button type="submit">Submit</button>
                </fieldset>
            </form>
            <ToastContainer />
        </div>
    );
}

function getAutoCompleteValue(key) {
    switch (key) {
        case 'address':
            return 'address-line1';
        case 'city':
            return 'address-level2';
        case 'state':
            return 'address-level1';
        case 'zipcode':
            return 'postal-code';
        case 'country':
            return 'country';
        default:
            return 'off';
    }
}

export default AddressForm;