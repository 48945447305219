import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './InfoForm.css';  // Import the custom CSS file
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function InfoForm() {
    const [formData, setFormData] = useState({
        gender: '',
        full_name: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        address: '',
        zipcode: '',
        city: '',
        state: '',
        country: ''
    });
    const [dataLoaded, setDataLoaded] = useState(false);
    const submitButtonRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const apiKey = urlParams.get('apikey');

            if (!apiKey) {
                toast.error('Missing key');
                return;
            }

            try {
                const ipResponse = await axios.get('https://ipinfo.io/ip');
                const ipAddress = ipResponse.data.trim();
                const response = await axios.get('https://apis.themindson.com/info-service/get-info', {
                    headers: {
                        'apikey': apiKey
                    },
                    params: {
                        'ip': ipAddress
                    },
                    timeout: 60000
                });
                if (response.status === 200) {
                    setFormData(response.data);
                } else {
                    setFormData({
                        gender: 'NULL',
                        full_name: 'NULL',
                        email: 'NULL',
                        first_name: 'NULL',
                        last_name: 'NULL',
                        phone: 'NULL',
                        address: 'NULL',
                        zipcode: 'NULL',
                        city: 'NULL',
                        state: 'NULL',
                        country: 'NULL'
                    });
                }
            } catch (error) {
                setFormData({
                    gender: 'NULL',
                    full_name: 'NULL',
                    email: 'NULL',
                    first_name: 'NULL',
                    last_name: 'NULL',
                    phone: 'NULL',
                    address: 'NULL',
                    zipcode: 'NULL',
                    city: 'NULL',
                    state: 'NULL',
                    country: 'NULL'
                });
                if (error.response.status === 403) {
                    toast.error(`Wrong Key`);
                } else {
                    toast.error(`Error fetching data: ${error}`);
                }
            } finally {
                setDataLoaded(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (dataLoaded && submitButtonRef.current) {
            submitButtonRef.current.click();
        }
    }, [dataLoaded]);

    const handleChange = (e) => {
        const {id, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleCopy = (field) => {
        navigator.clipboard.writeText(formData[field])
            .then(() => {
                toast.success(`${field.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())} copied to clipboard!`);
            })
            .catch(() => {
                toast.error('Failed to copy');
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <fieldset>
                    {Object.keys(formData).map((key) => (
                        <div key={key} className={`form-field ${key === 'gender' ? 'gender-field' : ''}`}>
                            <label htmlFor={key} style={{textTransform: 'capitalize'}}>
                                {key.replace('_', ' ')}:
                            </label>
                            <input
                                id={key}
                                type="text"
                                value={key === 'gender' ? formData[key].toUpperCase() : formData[key]}
                                onChange={handleChange}
                                name={key}
                                autoComplete={getAutoCompleteValue(key)}
                                className={key === 'gender' ? 'gender-input' : ''}
                            />
                            <button type="button" onClick={() => handleCopy(key)}>Copy</button>
                        </div>
                    ))}
                    <button type="submit" ref={submitButtonRef}>Submit</button>
                </fieldset>
            </form>
            <ToastContainer/>
        </div>
    );
}

function getAutoCompleteValue(key) {
    switch (key) {
        case 'full_name':
            return 'name';
        case 'email':
            return 'email';
        case 'first_name':
            return 'given-name';
        case 'last_name':
            return 'family-name';
        case 'phone':
            return 'tel';
        case 'address':
            return 'address-line1';
        case 'city':
            return 'address-level2';
        case 'state':
            return 'address-level1';
        case 'zipcode':
            return 'postal-code';
        case 'country':
            return 'country';
        default:
            return 'off';
    }
}

export default InfoForm;